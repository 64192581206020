<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryCurrentUtilityPage',
  watch: {
    currentUtilityPage() {
      this.$store.commit('setCurrentUtilityPage', this.currentUtilityPage);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
      cachePolicy: 'network-only',
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
      slug: computed(() => store.state.data.currentSlug),
    });

    const queryCurrentUtilityPage = gql`
      query ($slug: String!, $lang:I18NLocaleCode!) {
        utilities (locale: $lang, filters: { slug: { contains: $slug } }) {
          data {
            attributes {
              Titel
              Text
              download {
                bezeichnung
                file {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
              Seo {
                metaTitle
                metaDescription
              }
            }
          }
        }
      }
    `;
    const { data: currentUtilityPage } = useQuery({
      query: queryCurrentUtilityPage,
      variables,
      context,
    });
    return { currentUtilityPage };
  },
};
</script>
