<template>
<div id="page" class="w-full flex flex-col items-center">
  <div class="w-full min-h-screen font-rouben text-new-white mt-60 px-8 md:px-20 md:max-w-screen-2xl">
    <div :ref="setRef" data-anim="0" class="opacity-0 md:leading-tight tracking-wide w-full md:w-5/12 font-extrabold md:font-bold text-2xl md:text-6xl text-center md:text-left break-words hyphen">{{currentUtilityPage?.utilities?.data[0]?.attributes?.Titel}}</div>
    <div :ref="setRef" data-anim="0" v-html="convertMarkdown(currentUtilityPage?.utilities?.data[0]?.attributes?.Text)" class="opacity-0 md:w-6/12 markdown whitespace-pre-line text-base mb-3 leading-7 mt-3 lg:mt-0 text-center md:text-left" />
    <div :ref="setRef" data-anim="0" class="opacity-0 flex flex-col justify-start items-start w-full md:w-auto">
      <customButton :label="download?.bezeichnung" v-for="download, index in currentUtilityPage?.utilities?.data[0]?.attributes?.download" :key="index" class="w-full md:w-auto" @click="downloadFile(download?.file?.data?.attributes?.url)"/>
    </div>
  </div>

  <queryCurrentUtilityPage />
  <div class="w-full md:flex md:flex-col md:items-center">
    <footerComponent />
  </div>
</div>
</template>

<script>
// Mixins
import intersectionObserver from '../mixins/intersectionObserver.vue';
import convertMarkdown from '../mixins/convertMarkdown.vue';

// GraphQL
import queryCurrentUtilityPage from '../graphql/queryCurrentUtilityPage.vue';

// Components
import footerComponent from '../components/footer.vue';
import customButton from '../components/symbols/customButton.vue';

export default {
  name: 'Utility',
  components: { footerComponent, queryCurrentUtilityPage, customButton },
  mixins: [intersectionObserver, convertMarkdown],

  computed: {
    currentUtilityPage() {
      return this.$store.state.data.currentUtilityPage;
    },
  },

  watch: {
    currentUtilityPage() {
      const seo = this.currentUtilityPage?.utilities?.data[0]?.attributes?.Seo;
      document.title = seo?.metaTitle;
      document.querySelector('meta[name="description"]').setAttribute('content', seo?.metaDescription);
    },
  },

  mounted() {
    window.scrollTo({ top: 0, left: 0 });
  },

  methods: {
    downloadFile(url) {
      if (url) window.open(url, '_blank').focus();
    },
  },

};
</script>
