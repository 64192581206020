<template>
  <div class="transition duration-500 ease-in-out bg-new-black md:hover:bg-new-white min-w-36 mt-2 md:mt-3 px-9 py-4 rounded-lg cursor-pointer flex flex-row justify-center items-center select-none group"
    :class="{'border border-white' : mode !== 'dark'}">
    <div class="transition duration-500 ease-in-out  text-center tracking font-semibold text-lg md:text-lg text-new-white md:group-hover:text-new-black">{{label}}</div>
  </div>
</template>

<script>
export default {
  name: 'customButton',
  props: ['label', 'mode', 'inverted'],
};
</script>
